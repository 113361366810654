import React, { useState, useEffect, useCallback } from 'react';
import {
  Text,
  Container,
  Button,
  Box,
  Image,
  Input,
  VStack,
  Select,
  FormLabel,
  Flex,
  useToast,
  HStack,
  Circle,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  InputGroup,
  InputRightElement,
  Checkbox,
} from '@chakra-ui/react';
import {
  auth,
  firestore,
  collection,
  doc,
  setDoc,
  onSnapshot,
  getDocs,
  addDoc,
  updateDoc,
  query,
  where,
  getDoc,
  arrayUnion,
} from '../firebaseConfig';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { useAuth } from '../AuthProvider';
import LoadingModal from './LoadingModal';
import Logo from './Logo';
import { sendDiscordNotification } from '../utils/discord';
import { useNavigate } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { addEventToGoogleCalendar } from '../utils/googleCalendar';

const Trial = () => {
  const { currentUser, refreshUser } = useAuth();
  const toast = useToast();
  const navigate = useNavigate();

  // ステップは2段階に変更：1=登録、2=予約
  const [step, setStep] = useState(
    Number(localStorage.getItem('trialStep')) || (currentUser ? 2 : 1)
  );
  const [completedSteps, setCompletedSteps] = useState(() => {
    const storedSteps = JSON.parse(localStorage.getItem('trialCompletedSteps'));
    if (storedSteps) {
      return storedSteps;
    } else if (currentUser) {
      return [true, false];
    } else {
      return [false, false];
    }
  });
  const [registering, setRegistering] = useState(false);
  const [processingReservation, setProcessingReservation] = useState(false);
  const [loadingUserData, setLoadingUserData] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [path, setPath] = useState('');

  const [studioId, setStudioId] = useState('');
  const [studioName, setStudioName] = useState('');
  const [studios, setStudios] = useState([]);
  const [studioData, setStudioData] = useState(null);
  // 支払い関連の状態は削除
  // const [paymentCompleted, setPaymentCompleted] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [reservations, setReservations] = useState([]);
  const [selectedDateTime, setSelectedDateTime] = useState(() => {
    const stored = localStorage.getItem('selectedDateTime');
    if (stored) {
      const parsed = JSON.parse(stored);
      parsed.date = new Date(parsed.date); // Dateオブジェクトに変換
      return parsed;
    }
    return null;
  });
  const [dateOffset, setDateOffset] = useState(0);
  // 支払い処理削除のため、initialPriceの取得処理は不要
  // const [initialPrice, setInitialPrice] = useState({});
  // const [preparing, setPreparing] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [agreementChecked, setAgreementChecked] = useState(false);

  const handlers = useSwipeable({
    onSwipedLeft: () => handleNextDays(),
    onSwipedRight: () => handlePrevDays(),
  });

  // 体験予約可能な時間帯（共通）
  const timeSlots = [
    { hour: 8, minute: 0 },
    { hour: 9, minute: 0 },
    { hour: 10, minute: 0 },
    { hour: 11, minute: 0 },
    { hour: 12, minute: 30 },
    { hour: 13, minute: 30 },
    { hour: 14, minute: 30 },
    { hour: 15, minute: 30 },
    { hour: 16, minute: 30 },
    { hour: 17, minute: 30 },
    { hour: 19, minute: 0 },
    { hour: 20, minute: 0 },
    { hour: 21, minute: 0 },
    { hour: 22, minute: 0 },
  ];

  // 支払いステップ削除のため、ステップ1は登録、ステップ2は予約
  const isStepCompleted = step => {
    switch (step) {
      case 1:
        return email && password && fullName;
      case 2:
        return selectedDateTime && studioId;
      default:
        return false;
    }
  };

  // 画面上部にスクロールする処理
const handleEditScroll = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
  // 選択した日時をリセット
  setSelectedDateTime(null);
  localStorage.removeItem('selectedDateTime');
};

// メールアドレスの修正ボタン押下時の処理（ログアウト後に /trial へ遷移）
const handleEmailEdit = async () => {
  try {
    await auth.signOut();
    navigate('/trial'); // /trial に遷移
  } catch (error) {
    console.error('ログアウトエラー:', error);
  }
};

// スタジオ選択時の処理：Firestore のユーザードキュメントを更新し、ユーザー情報をリフレッシュする
const handleStudioSelect = async (id) => {
  // 選択されたスタジオが変わる場合、既存の日時情報をリセットする
  if (studioId && id !== studioId) {
    setSelectedDateTime(null);
    localStorage.removeItem('selectedDateTime');
  }
  setStudioId(id);
  const selectedStudio = studios.find(s => s.id === id);
  setStudioName(selectedStudio?.name);

  if (currentUser && id) {
    try {
      // ユーザードキュメントの studioId を更新
      const userRef = doc(firestore, 'users', currentUser.uid);
      await updateDoc(userRef, {
        studioId: id,
        // 必要に応じて、所属スタジオの情報を更新する
        // 例: ['belongStudioIds.' + id]: selectedStudio.name など
      });
      // ユーザー情報のリフレッシュ
      await auth.currentUser.reload();
      // 画面の再描画を促すために、必要であれば state を更新する
      // 例えば、ステップを再設定するなど（今回の場合はそのままで問題なければ不要）
    } catch (error) {
      console.error('スタジオ更新エラー:', error);
      toast({
        title: 'エラー',
        description: 'スタジオの更新に失敗しました。再度お試しください。',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  }
};


  // 利用可能なスタジオを取得
  useEffect(() => {
    const studiosCollection = collection(firestore, 'studios');
    const unsubscribe = onSnapshot(
      studiosCollection,
      snapshot => {
        const studiosArray = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        const openStudiosArray = studiosArray.filter(
          studio => studio.open === true
        );
        setStudios(openStudiosArray);
        if (openStudiosArray?.length > 0 && !studioId) {
          setStudioId(openStudiosArray[0]?.id);
          setStudioName(openStudiosArray[0]?.name);
        }
      },
      error => console.error('Error fetching studios:', error)
    );

    return () => unsubscribe();
  }, [studioId]);

  // 選択したスタジオのデータを取得
  useEffect(() => {
    if (studioId) {
      const fetchStudioData = async () => {
        const studioRef = doc(firestore, 'studios', studioId);
        const studioSnap = await getDoc(studioRef);
        if (studioSnap.exists()) {
          setStudioData(studioSnap.data());
        } else {
          console.log('No such studio!');
        }
      };
      fetchStudioData();
    }
  }, [studioId]);

  // ユーザーがすでに登録済みの場合は予約ステップに進む
  useEffect(() => {
    if (currentUser) {
      setLoadingUserData(false);
      if (!completedSteps[0]) {
        setCompletedSteps(prev => {
          const newCompletedSteps = [...prev];
          newCompletedSteps[0] = true;
          localStorage.setItem(
            'trialCompletedSteps',
            JSON.stringify(newCompletedSteps)
          );
          return newCompletedSteps;
        });
        setStep(2);
        localStorage.setItem('trialStep', '2');
      }
    } else {
      setStep(1);
      localStorage.setItem('trialStep', '1');
      setLoadingUserData(false);
    }
  }, [completedSteps, currentUser]);

  // 既に予約が確定している場合は待機画面へ遷移
  useEffect(() => {
    if (currentUser?.trialId?.length > 0) {
      setCompletedSteps(prev => {
        const newCompletedSteps = [...prev];
        newCompletedSteps[1] = true;
        localStorage.setItem(
          'trialCompletedSteps',
          JSON.stringify(newCompletedSteps)
        );
        return newCompletedSteps;
      });
      navigate('/waiting');
    }
  }, [currentUser, navigate]);

  // コンポーネントマウント時にメールアドレスとパスワード以外の入力項目を復元する
useEffect(() => {
  const storedFullName = localStorage.getItem('fullName');
  const storedDisplayName = localStorage.getItem('displayName');
  const storedPath = localStorage.getItem('path');
  if (storedFullName) setFullName(storedFullName);
  if (storedDisplayName) setDisplayName(storedDisplayName);
  if (storedPath) setPath(storedPath);
}, []);

// 担当者情報を算出する関数
const getInstructor = () => {
  if (!studioData || !selectedDateTime) return '';
  const weekdays = ['日', '月', '火', '水', '木', '金', '土'];
  const weekday = weekdays[selectedDateTime.date.getDay()];
  const timeStr = `${String(selectedDateTime.hour).padStart(2, '0')}${String(selectedDateTime.minute).padStart(2, '0')}`;
  const shiftKey = `${weekday}${timeStr}`;
  // studioData.shifts[shiftKey]は配列になっている前提
  return currentUser?.studio?.instructors[studioData.shifts?.[shiftKey]?.[0]] || '未定';
};


  // 予約可能な時間帯の取得
  const fetchReservations = useCallback(async () => {
    if (!studioId) return;

    setFetching(true);
    const reservationsRef = collection(firestore, 'reservations');
    const q = query(
      reservationsRef,
      where('studioId', '==', studioId),
      where('active', '==', true),
      where('startDate', '>=', new Date().toISOString())
    );
    const querySnapshot = await getDocs(q);
    const reservations = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));
    setReservations(reservations);
    setFetching(false);
  }, [studioId]);

  useEffect(() => {
    if (step === 2) {
      fetchReservations();
    }
  }, [fetchReservations, step]);

  // 日付と時間を結合してISO形式の文字列を作成
  const combineDateTime = (date, hour, minute) => {
    if (typeof date === 'string') {
      date = new Date(date);
    }

    if (!(date instanceof Date) || isNaN(date)) {
      console.error('Invalid date object:', date);
      return null;
    }

    const dateStr = date
      .toLocaleDateString('ja-JP', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(/\//g, '-');

    const dateTimeStr = `${dateStr}T${String(hour).padStart(2, '0')}:${String(
      minute
    ).padStart(2, '0')}`;

    return dateTimeStr;
  };

  // 既に予約が入っているかどうかのチェック
  const isTimeSlotReserved = (date, hour, minute) => {
    const slotStart = new Date(date);
    slotStart.setHours(hour, minute, 0, 0);
    const slotEnd = new Date(slotStart.getTime() + 60 * 60 * 1000);

    return reservations.some(reservation => {
      const reservationStart = new Date(reservation.startDate);
      const reservationEnd = new Date(reservation.endDate);

      return slotStart < reservationEnd && slotEnd > reservationStart;
    });
  };

  // 表示する日付の範囲（7日間）
  const getNextDays = offset => {
    const days = [];
    const today = new Date();
    today.setDate(today.getDate() + offset);
    let currentMonth = today.getMonth();
    for (let i = 1; i < 8; i++) {
      const day = new Date(today);
      day.setDate(today.getDate() + i);
      if (day.getMonth() !== currentMonth) {
        currentMonth = day.getMonth();
        days.push({ date: day, newMonth: true });
      } else {
        days.push({ date: day, newMonth: false });
      }
    }
    return days;
  };
  const days = getNextDays(dateOffset) || [];

  // スタジオシフトの有無をチェック
  const isShiftAvailable = useCallback(
    (date, hour, minute) => {
      if (!studioData || !studioData.shifts) {
        return false;
      }
      const weekday = ['日', '月', '火', '水', '木', '金', '土'][date.getDay()];
      const timeStr = `${String(hour).padStart(2, '0')}${String(
        minute
      ).padStart(2, '0')}`;
      const shiftKey = `${weekday}${timeStr}`;
      return (
        studioData.shifts[shiftKey] && studioData.shifts[shiftKey].length > 0
      );
    },
    [studioData]
  );

  // 固定スロットのチェック（休憩時間等）
  const isFixedSlot = useCallback(
    (date, hour, minute) => {
      if (!studioData || !studioData.fixies) {
        return false;
      }
      const weekday = ['日', '月', '火', '水', '木', '金', '土'][date.getDay()];
      const timeStr = `${String(hour).padStart(2, '0')}${String(
        minute
      ).padStart(2, '0')}`;
      return studioData.fixies.some(
        fixie => fixie.weekday === weekday && fixie.start === timeStr
      );
    },
    [studioData]
  );

  // 日付・時間をフォーマットして表示
  const formatDateTime = (date, hour, minute) => {
    const day = new Date(date);
    const formattedDate = `${day.getFullYear()}年${day.getMonth() + 1}月${day.getDate()}日`;
    return `${formattedDate} ${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`;
  };

  // 予約枠の選択処理
  const handleReserveClick = (date, hour, minute) => {
    const selected =
      selectedDateTime &&
      selectedDateTime.date.toDateString() === date.toDateString() &&
      selectedDateTime.hour === hour &&
      selectedDateTime.minute === minute;

    if (selected) {
      setSelectedDateTime(null);
      localStorage.removeItem('selectedDateTime');
    } else {
      if (isTimeSlotReserved(date, hour, minute)) {
        alert('この時間帯は他の人の予約枠です。');
      } else {
        const newSelectedDateTime = {
          date: date, // Dateオブジェクトそのまま
          hour,
          minute,
        };
        setSelectedDateTime(newSelectedDateTime);
        localStorage.setItem('selectedDateTime', JSON.stringify(newSelectedDateTime));
      }
    }
  };

  // 予約の確定処理（Google Calendar登録・Firestoreへの予約追加など）
  const confirmReservation = async () => {
    if (!currentUser) {
      toast({
        title: 'エラー',
        description: 'ユーザー情報を取得できません。',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setProcessingReservation(true);

    // スタジオIDをユーザーデータに保存
    const userRef = doc(firestore, 'users', currentUser.uid);
    await updateDoc(userRef, {
      studioId: studioId,
      ['belongStudioIds.' + studioId]: studioName.slice(5),
    });

    const startDateTime = combineDateTime(
      selectedDateTime.date,
      selectedDateTime.hour,
      selectedDateTime.minute
    );
    const endDateTime = combineDateTime(
      selectedDateTime.date,
      selectedDateTime.hour + 1,
      selectedDateTime.minute
    );

    try {
      // Google Calendarにイベントを追加
      const eventData = await addEventToGoogleCalendar(
        currentUser,
        '体験',
        '体験予約画面',
        startDateTime,
        endDateTime,
        studioData
      );

      const weekday = ['日', '月', '火', '水', '木', '金', '土'][selectedDateTime.date.getDay()];
      const timeStr = `${String(selectedDateTime.hour).padStart(2, '0')}${String(selectedDateTime.minute).padStart(2, '0')}`;
      const shiftKey = `${weekday}${timeStr}`;
      const instructorId = studioData.shifts[shiftKey]?.[0];

      // 予約をFirestoreに追加
      const reservation = await addDoc(collection(firestore, 'reservations'), {
        startDate: startDateTime,
        endDate: endDateTime,
        userId: currentUser.uid,
        description: currentUser.fullName || '',
        studioId: studioId,
        ticketId: null,
        eventId: eventData.id,
        visited: false,
        active: true,
        changed: false,
        type: 'trial',
        instructorId: instructorId,
      });

      // ユーザー情報に予約IDを保存
      await updateDoc(userRef, {
        trialId: reservation.id,
      });

      // Discordへ通知
      const embeds = [
        {
          title: '【' + studioName + '】新規体験のご予約が入りました✨',
          description: '体験レッスン予約ページからお客さま自身が入力しました。',
          color: 15158332,
          fields: [
            { name: '氏名', value: currentUser.fullName, inline: true },
            { name: 'LINE表示名', value: currentUser.displayName, inline: true },
            { name: 'メールアドレス', value: currentUser.email, inline: true },
            { name: '認知経路', value: path, inline: true },
            { name: '開始時刻', value: `${startDateTime.replace('T', ' ')}`, inline: true },
            { name: '終了時刻', value: `${endDateTime.replace('T', ' ')}`, inline: true },
            { name: '担当者', value: currentUser?.studio?.instructors[instructorId] || '未定', inline: true },
          ],
          footer: { text: 'UID: ' + currentUser.uid },
          timestamp: new Date().toISOString(),
        },
      ];

      await sendDiscordNotification(studioData.slug, embeds);
      await sendDiscordNotification('trial', embeds);
      await sendDiscordNotification('audit', embeds);

      // ローカルストレージの不要な項目をクリア
      localStorage.removeItem('selectedDateTime');
      localStorage.removeItem('trialStep');
      localStorage.removeItem('trialCompletedSteps');

      setProcessingReservation(false);
      navigate('/waiting');
    } catch (error) {
      window.alert('この枠は何らかの理由で予約できないため、別の日時でご予約ください。');
      console.error('予約の追加に失敗しました', error);

      const errorEmbeds = [
        {
          title: '体験予約確定時にエラー発生',
          description: '体験予約の確定処理中にエラーが発生しました。',
          color: 16711680,
          fields: [
            { name: '対象者', value: currentUser?.email || '', inline: true },
            { name: 'エラー内容', value: error.message, inline: false },
          ],
          footer: { text: 'USER ID:' + currentUser?.uid },
          timestamp: new Date().toISOString(),
        },
      ];
      await sendDiscordNotification('trial', errorEmbeds);
      await sendDiscordNotification('audit', errorEmbeds);

      setProcessingReservation(false);
      navigate('/trial');
    }
  };

  const handlePrevDays = () => {
    setDateOffset(prev => Math.max(prev - 7, 0));
  };

  const handleNextDays = () => {
    setDateOffset(prev => Math.min(prev + 7, 28));
  };

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  // 各ステップごとの表示内容
  const stepContent = () => {
    if ((loadingUserData && step !== 1) || registering) {
      // ユーザーデータ読み込み中はローディング表示
      return <LoadingModal text="ユーザーデータを読み込み中..." />;
    }

    switch (step) {
      case 1:
        return (
          <>
            <Flex flexDirection="column" alignItems="start" width="full">
              <FormLabel fontSize="12" mb="1">
                お名前 <span style={{ color: 'red' }}>*</span>
              </FormLabel>
              <Input
                name="name"
                placeholder="漢字フルネーム"
                value={fullName}
                onChange={e => {
                  setFullName(e.target.value);
                  localStorage.setItem('fullName', e.target.value);
                }}
              />
            </Flex>
            <Flex flexDirection="column" alignItems="start" width="full">
              <FormLabel fontSize="12" mb="1">
                LINEの表示名 <span style={{ color: 'red' }}>*</span>
              </FormLabel>
              <Input
                name="displayName"
                placeholder="LINEの表示名"
                value={displayName}
                onChange={e => {
                  setDisplayName(e.target.value);
                  localStorage.setItem('displayName', e.target.value);
                }}
              />
            </Flex>
            <Flex flexDirection="column" alignItems="start" width="full">
              <FormLabel fontSize="12" mb="1">
                メールアドレス <span style={{ color: 'red' }}>*</span>
              </FormLabel>
              <Input
                name="email"
                placeholder="Email"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </Flex>
            <Flex flexDirection="column" alignItems="start" width="full">
              <FormLabel fontSize="12" mb="1">
                パスワード <span style={{ color: 'red' }}>*</span>
              </FormLabel>
              <InputGroup>
                <Input
                  type={showPassword ? 'text' : 'password'}
                  placeholder="パスワード"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? '隠す' : '見る'}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </Flex>

            <Flex flexDirection="column" alignItems="start" width="full">
              <FormLabel fontSize="12" mb="1">
                medyを知ったきっかけ <span style={{ color: 'red' }}>*</span>
              </FormLabel>
              <Select
                value={path}
                onChange={e => {
                  setPath(e.target.value);
                  localStorage.setItem('path', e.target.value);
                }}
                placeholder="選択してください"
              >
                <option key="search" value="ウェブ検索">
                  ウェブ検索
                </option>
                <option key="instagram" value="Instagram">
                  Instagram
                </option>
                <option key="signborad" value="看板">
                  看板
                </option>
                <option key="leaflet" value="チラシ">
                  チラシ
                </option>
                <option key="map" value="Googleマップ">
                  Googleマップ
                </option>
                <option key="friend" value="友人の紹介">
                  友人の紹介
                </option>
              </Select>
            </Flex>

            <Button
              onClick={handleSignUp}
              colorScheme="blue"
              width="full"
              isLoading={registering}
            >
              登録して次へ
            </Button>
          </>
        );
      case 2:
        return (
          <>
            <Flex flexDirection="column" alignItems="start" width="full">
              <FormLabel fontSize="12" mb="1">
                スタジオを選択してください <span style={{ color: 'red' }}>*</span>
              </FormLabel>
              {/* スタジオの表示（修正後） */}
<Select
  value={studioId}
  onChange={e => handleStudioSelect(e.target.value)}
>
<option value="">スタジオを選択してください</option>
  {studios?.map(s => (
    <option key={s?.id} value={s?.id}>
      {s?.name}
    </option>
  ))}
</Select>

            </Flex>
            {fetching && (
              <LoadingModal text="予約が可能な時間を問い合わせ中..." />
            )}
            {processingReservation && <LoadingModal text="予約を処理中..." />}
            {!fetching && !processingReservation && (
              <Box
                borderWidth="0px"
                borderRadius="xl"
                overflow="hidden"
                p={4}
                mt={4}
                backgroundColor={'#fdfdfd'}
                boxShadow="sm"
                {...handlers}
              >
                <HStack justifyContent="space-between" mb={2}>
                  <Button
                    size="sm"
                    onClick={handlePrevDays}
                    disabled={dateOffset === 0}
                    colorScheme={dateOffset === 0 ? 'gray' : 'teal'}
                  >
                    前へ
                  </Button>
                  <Text fontWeight="bold">{getMonthRange(days)}</Text>
                  <Button
                    size="sm"
                    onClick={handleNextDays}
                    disabled={dateOffset >= 21}
                    colorScheme={dateOffset >= 21 ? 'gray' : 'teal'}
                  >
                    次へ
                  </Button>
                </HStack>
                <Table variant="simple" size="xs">
                  <Thead>
                    <Tr>
                      <Th style={{ textAlign: 'center', fontSize: 12 }}>時間</Th>
                      {days.length > 0 &&
                        days.map((day, index) => (
                          <Th
                            style={{
                              textAlign: 'center',
                              marginBottom: '4px',
                              fontSize: 10,
                              color:
                                day.date.getDay() === 0
                                  ? 'red'
                                  : day.date.getDay() === 6
                                  ? 'blue'
                                  : 'black',
                            }}
                            key={day.date.toDateString()}
                          >
                            {`${day.date.getDate()}日 (${
                              ['日', '月', '火', '水', '木', '金', '土'][day.date.getDay()]
                            })`}
                          </Th>
                        ))}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {timeSlots.length > 0 &&
                      timeSlots.map(slot => (
                        <Tr key={`${slot.hour}:${slot.minute}`}>
                          <Td
                            style={{
                              textAlign: 'center',
                              fontSize: 12,
                              paddingRight: 4,
                            }}
                          >{`${String(slot.hour).padStart(2, '0')}:${String(slot.minute).padStart(2, '0')}`}</Td>
                          {days.map(day => (
                            <Td
                              key={`${day.date.toDateString()}-${slot.hour}:${slot.minute}`}
                              onClick={() =>
                                handleReserveClick(day.date, slot.hour, slot.minute)
                              }
                              style={{
                                fontSize: 12,
                                cursor:
                                  !isFixedSlot(day.date, slot.hour, slot.minute) &&
                                  !isTimeSlotReserved(day.date, slot.hour, slot.minute) &&
                                  isShiftAvailable(day.date, slot.hour, slot.minute)
                                    ? 'pointer'
                                    : 'default',
                                textAlign: 'center',
                                backgroundColor:
                                  selectedDateTime &&
                                  selectedDateTime.date.toDateString() === day.date.toDateString() &&
                                  selectedDateTime.hour === slot.hour &&
                                  selectedDateTime.minute === slot.minute
                                    ? '#afc7fb'
                                    : !isFixedSlot(day.date, slot.hour, slot.minute) &&
                                      isShiftAvailable(day.date, slot.hour, slot.minute) &&
                                      !isTimeSlotReserved(day.date, slot.hour, slot.minute)
                                    ? '#eafffa'
                                    : 'silver',
                              }}
                            >
                              {isFixedSlot(day.date, slot.hour, slot.minute) ||
                              isTimeSlotReserved(day.date, slot.hour, slot.minute) ||
                              !isShiftAvailable(day.date, slot.hour, slot.minute)
                                ? '×'
                                : '◯'}
                            </Td>
                          ))}
                        </Tr>
                      ))}
                  </Tbody>
                </Table>
              </Box>
            )}
<VStack spacing={4} mt={2} align="center">
  {/* 体験料金の確認カード */}
  <Box
    borderWidth="1px"
    borderRadius="lg"
    overflow="hidden"
    p={4}
    backgroundColor="white"
    boxShadow="sm"
    textAlign="center"
    maxW="400px"
    w="full"
  >
    <Text fontSize="16" color="teal.600" fontWeight="bold">
      体験料金は半額キャンペーン中<br/><strong>4,000円</strong>です🌟
    </Text>
    <Text fontSize="14" color="gray.600" my={4}>
      お会計用にカードをご持参ください💳
    </Text>
    <Text fontSize="14" color="red.500" mt={2}>
      さらに、レッスンの感想を記入📝で<br/>
      <strong>4,000円キャッシュバック中！<br/><br/>実質無料で体験を受けられます</strong>！
    </Text>
  </Box>

  {/* メールアドレスの表示 */}
  {currentUser?.email && (
    <Box maxW="400px" w="full" mt={-1}>
      <Flex justify="space-between" align="center">
        <Text fontSize="xs" color="gray.600" mb={1}>
          メールアドレス
        </Text>
        <Button size="xs" colorScheme="gray" mb={1} onClick={handleEmailEdit}>
          修正する
        </Button>
      </Flex>
      <Box
        p={3}
        borderWidth="1px"
        borderRadius="md"
        borderColor="gray.300"
        textAlign="center"
      >
        <Text fontSize="14" color="gray.600">
          <strong>{currentUser?.email || '未入力'}</strong>
        </Text>
      </Box>
    </Box>
  )}

  {/* スタジオの表示 */}
  <Box maxW="400px" w="full">
    <Flex justify="space-between" align="center">
      <Text fontSize="xs" color="gray.600" mb={1}>
        スタジオ
      </Text>
      <Button size="xs" colorScheme="gray" mb={1} onClick={handleEditScroll}>
        別のスタジオにする
      </Button>
    </Flex>
    <Box
      p={3}
      borderWidth="1px"
      borderRadius="md"
      borderColor="gray.300"
      textAlign="center"
    >
      <Text fontSize="14" color="gray.600">
        <strong>{studioName}</strong>
      </Text>
      {selectedDateTime && studioData && (
        <Flex flexDirection="column" alignItems="center" mt={2}>
          <Image
            src={getInstructor() ? `${getInstructor()}.jpg` : 'logo192.png'}
            onError={e => {
              e.target.src = 'logo512.png';
            }}
            alt={getInstructor() || 'インストラクター'}
            borderRadius="full"
            border="0.5px solid teal"
            boxSize="80px"
            mb={2}
          />
          <Text fontSize="14" color="gray.600" textAlign="center">
            担当：{getInstructor()}
          </Text>
        </Flex>
      )}
    </Box>
  </Box>

  {/* 予約日時の確認 */}
  {selectedDateTime ? (
    <Box maxW="400px" w="full" mt={-1}>
      <Flex justify="space-between" align="center">
        <Text fontSize="xs" color="gray.600" mb={1}>
          体験ご来店の日時
        </Text>
        <Button size="xs" colorScheme="gray" mb={1} onClick={handleEditScroll}>
          別の日時に設定する
        </Button>
      </Flex>
      <Box
        p={3}
        borderWidth="1px"
        borderRadius="md"
        borderColor="gray.300"
        textAlign="center"
      >
        <Text fontSize="16" fontWeight="bold" color="teal.600">
          {formatDateTime(
            selectedDateTime.date,
            selectedDateTime.hour,
            selectedDateTime.minute
          )}
        </Text>
      </Box>
    </Box>
  ) : (
    <Text fontSize="14" color="red.500" mt={2}>
      体験レッスンの希望時間を選択してください
    </Text>
  )}

  {/* 同意チェックボックス */}
  <HStack spacing={3} align="center">
    <Checkbox
      isChecked={agreementChecked}
      onChange={e => setAgreementChecked(e.target.checked)}
      colorScheme="blue"
    >
      <Text fontSize="14">
        入力内容に間違いありません
      </Text>
    </Checkbox>
  </HStack>

  {/* 予約確定ボタン */}
  <Button
    colorScheme="blue"
    my={2}
    onClick={confirmReservation}
    isDisabled={!selectedDateTime || !currentUser || !agreementChecked}
    maxW="400px"
    w="full"
  >
    予約を確定する
  </Button>
</VStack>


          </>
        );
      default:
        return null;
    }
  };

  // 2ステップの場合のステップ表示（クリックで戻れる）
  const stepCircles = () => {
    const steps = [1, 2];
    return (
      <HStack spacing={4} justify="center" mb={4}>
        {steps.map(num => {
          const isClickable = num === 1 || completedSteps.slice(0, num - 1).every(Boolean);
          return (
            <Circle
              key={num}
              size="40px"
              bg={
                completedSteps[num - 1]
                  ? 'green.500'
                  : num === step
                  ? 'blue.500'
                  : 'gray.200'
              }
              color="white"
              cursor={isClickable ? 'pointer' : 'not-allowed'}
              onClick={() => isClickable && setStep(num)}
            >
              {completedSteps[num - 1] ? '✓' : num}
            </Circle>
          );
        })}
      </HStack>
    );
  };

  // ユーザー登録処理
  const handleSignUp = async () => {
    if (!isStepCompleted(1)) {
      toast({
        title: 'エラー',
        description: 'メールアドレス、パスワード、氏名を入力してください。',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      setRegistering(true);
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      const userRef = doc(firestore, 'users', user.uid);
      await setDoc(
        userRef,
        {
          email: email,
          fullName: fullName,
          displayName: displayName,
          createdAt: new Date(),
          updatedAt: new Date(),
          path: path,
          // 後でスタジオIDを設定する
          belongStudioIds: {},
          manageStudioIds: [],
          workingStudioIds: [],
          sex: 'female',
          status: false,
          trial_lesson: false,
          trial_status: 0,
          member: false,
          instructor: false,
          initial: false,
          initial_discount: 100,
          trial_discount: 50,
          plan: 0,
          fixies: [],
          allowEmail: true,
          login: false,
          active: true,
        },
        { merge: true }
      );

      const studioRef = doc(firestore, 'studios', studioId);
      await setDoc(studioRef, { users: arrayUnion(userRef) }, { merge: true });
      // ユーザー情報を更新
      await refreshUser();

      setRegistering(false);
      setCompletedSteps(prev => {
        const newCompletedSteps = [...prev];
        newCompletedSteps[0] = true;
        localStorage.setItem(
          'trialCompletedSteps',
          JSON.stringify(newCompletedSteps)
        );
        return newCompletedSteps;
      });
      setStep(2);
      localStorage.setItem('trialStep', '2');
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        toast({
          title: 'エラー',
          description: '既に登録済みのメールアドレスです。パスワードを確認してください。',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setRegistering(false);
      } else {
        console.error('Error during sign-up', error);
        setRegistering(false);
      }
    }
  };

  // ヘッダーの日付部分の表示（複数月の場合の表記）
  const getMonthRange = days => {
    if (!days || days.length === 0) return '';
    const months = [...new Set(days.map(day => day.date.getMonth() + 1))];
    return months.length > 1 ? `${months[0]}月・${months[1]}月` : `${months[0]}月`;
  };

  if (registering || processingReservation) {
    return <LoadingModal text="処理中..." />;
  }

  return (
    <Container
      backgroundColor={'#ede9e5'}
      borderRadius={'10'}
      mt={4}
      pt={8}
      pb="4"
    >
      <Logo text="体験レッスンご予約の受付" />
      {stepCircles()}
      <Box
        borderWidth="0px"
        borderRadius="xl"
        overflow="hidden"
        p={4}
        mt={4}
        backgroundColor={'#fdfdfd'}
        boxShadow="sm"
      >
        <VStack spacing="12px">{stepContent()}</VStack>
      </Box>
    </Container>
  );
};

export default Trial;
